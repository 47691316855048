/* Body */

body {
    background-color: #F4FCD9;
    font-family: 'Sarabun', sans-serif;
    text-align: center;
}

p {
    font-size: 1.1rem;
}

li {
    list-style: none;
}

td {
    width: 30%;
}

.base-page {
    margin-top: 115px;
}

.sections {
    padding-bottom: 15px;
    display: inline-block;
    width: 800px;
}

.sub-title {
    color: #395144;
    display: inline-block;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 12px;
    padding-top: 25px;
    text-align: center;
    width: fit-content;
}

.u-list {
    font-size: 1.1rem;
    margin: 0 auto;
    padding-left: 0;
    text-align: left;
    width: fit-content;
}

.list-item {
    display: flex;
    padding: 8px 0;
}

.list-title {
    display: contents;
}

.green-bold {
    font-size: 1.2rem;
    font-weight: 800;
    color: #395144;
}

/* Navbar */

.page-title {
    color: #CDB30C;
    font-family: 'PT Sans', sans-serif;
    font-size: 3.5rem;
    font-weight: 800;
    margin: auto;
    text-align: center;
}

.navbar {
    background-color: #395144;
    padding: 0 0;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1;
}

.navbar-brand {
    color: #F4FCD9;
    font-family: 'Mogra', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    padding:0;
    position: relative;
    right: 24px;
}

.navbar-brand:hover {
    color: #F4FCD9;
}

.nav-box {
    margin: auto;
    text-align: center;
}

.nav-link {
    color: #F4FCD9;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    margin: -4px 20px 0;
}

.nav-link:focus {
    color: #F4FCD9;
}

.nav-link:hover {
    color: #D8EBB5;
}

.nav-phone {
    color: #F4FCD9;
    font-size: 1.2rem;
    position: fixed;
    top: 76px;
    right: 18px;
}

/* Home */

.introduction {
    padding-left: 10px;
    padding-top: 20px;
    text-align: left;
    width: 550px;
}

.why-intro {
    text-align: left;
}

.contact-phone {
    margin: 20px auto;
    text-align: center;
}

.images-home {
    border: #61481C;
    border-style: solid;
    border-width: 4px;
    margin: 12px .4% 0 .4%;
    height: auto;
    width: 285px;
}

.fa-solid {
    color: #395144;
    padding-right: 15px;
    position: relative;
    top: 5px;
}

.why-list {
    padding-left: 10px;
    width: 550px;
}

.discount-bg {
    background-color: #D8EBB5;
}

.fa-church {
    padding-right: 6px;
    position: relative;
    right: 4px;
}

.past-work {
    margin-bottom: 4px;
}

.special {
    min-height: 400px;
    padding-bottom: 100px;
}

.special-header {
    margin-bottom: 3%;
}

.special-details {
    line-height: 1.7;
}

/* Services */

.service-list {
    width: 550px;
}

/* Equipment */

.equip-list {
    width: 560px;
}

/* Contact */

.c-name {
    margin: 20px auto 0;
}

.locations-table {
    margin: auto;
    width: 70%;
}

.locations-header {
    text-align: center;
}

/* Footer */

.footer {
    background-color: #395144;
    bottom: 0px;
    min-height: 38px;
    position: fixed;
    text-align: center;
    width: 100%;
}

.foot-text {
    color: #F4FCD9;
    font-size: 1.8rem;
}

/* VIEWPORT UNDER 900px */

@media screen and (max-width: 900px) {

/* Body */

.base-page {
    margin-top: 100px;
}

.sections {
    width: 580px;
}

.sub-title {
    font-size: 1.9rem;
}

.u-list {
    font-size: 1.1rem;
}

.why-list {
    width: 95%;
}

/* Navbar */

.page-title {
    font-size: 3rem;
}

.navbar-brand {
    font-size: 1.3rem;
}

.nav-item {
    width: fit-content;
}

.nav-link {
    font-size: 1.3rem;
    font-weight: 600;
    margin: -4px 10px 0;
}

.nav-phone {
    font-size: 1rem;
    top: 66px;
    right: 5px;
}

/* Home */

.images-home {
    width: 32%;
}

/* Services */

.service-list {
    width: 95%;
}

.equip-list {
    width: 97%;
}

}

/* VIEWPORT UNDER 600px */

@media screen and (max-width: 600px) {

    .base-page {
        margin-top: 115px;
    }

    .sections {
        width: 300px;
    }

    .container-fluid {
        padding: 0;
    }
    
    /* Navbar */
    
    .page-title {
        font-size: 1.6rem;
    }

    .navbar-nav {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    
    .navbar-brand {
        font-size: 1.1rem;
        right: 3px;
    }
    
    .nav-item {
        width: fit-content;
    }
    
    .nav-link {
        font-size: 1.1rem;
        font-weight: 600;
        margin: -4px 10px 0;
    }

    .nav-phone {
        /* display: contents; */
        top: 62px;
        font-size: .9rem;
        right: 6px;
        width: 60px;
    }
    
    /* Home */
    
    .images-home {
        border-width: 3px;
        margin-top: 0;
    }

    .locations-table {
        width: 95%;
    }

    .td {
        font-size: 1.1rem;
        width: 50%;
    }

}